<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-8">
        <template v-if="user">
          <h4>{{getUser(user).label}}</h4>
        </template>
        <table>
          <thead>
            <tr>
              <th class="as">Periodo</th>
              <th class="ae">Venduto</th>
              <th class="ae">CCF (€)</th>
              <th class="ae">CCF (%)</th>
              <th class="ae">Copertura team fisso (€)</th>
              <th class="ae">Copertura team p.iva (€)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="as">{{cea.year-1}} 🕰</td>
              <td class="ae">{{$niceMoney(pv_getIncValueSoldEur((cea.year-1), user))}}</td>
              <td class="ae">{{$niceMoney(pv_getIncValueCCFEur((cea.year-1), user))}}</td>
              <td class="ae">{{$nicePerc(pv_getIncValueCCFPerc((cea.year-1), user))}}</td>
              <td class="ae">{{$niceMoney(pv_getIncValueCTFEur((cea.year-1), user))}}</td>
              <td class="ae">{{$niceMoney(pv_getIncValueCTPEur((cea.year-1), user))}}</td>
            </tr>
            <tr>
              <td class="as">Q1 🌸</td>
              <td class="ae">{{$niceMoney(pv_getIncValueSoldEur(1, user))}}</td>
              <td class="ae">{{$niceMoney(pv_getIncValueCCFEur(1, user))}}</td>
              <td class="ae">{{$nicePerc(pv_getIncValueCCFPerc(1, user))}}</td>
              <td class="ae">{{$niceMoney(pv_getIncValueCTFEur(1, user))}}</td>
              <td class="ae">{{$niceMoney(pv_getIncValueCTPEur(1, user))}}</td>
            </tr>
            <tr>
              <td class="as">Q2 ☀️</td>
              <td class="ae">{{$niceMoney(pv_getIncValueSoldEur(2, user))}}</td>
              <td class="ae">{{$niceMoney(pv_getIncValueCCFEur(2, user))}}</td>
              <td class="ae">{{$nicePerc(pv_getIncValueCCFPerc(2, user))}}</td>
              <td class="ae">{{$niceMoney(pv_getIncValueCTFEur(2, user))}}</td>
              <td class="ae">{{$niceMoney(pv_getIncValueCTPEur(2, user))}}</td>
            </tr>
            <tr>
              <td class="as">Q3 ❄️</td>
              <td class="ae">{{$niceMoney(pv_getIncValueSoldEur(3, user))}}</td>
              <td class="ae">{{$niceMoney(pv_getIncValueCCFEur(3, user))}}</td>
              <td class="ae">{{$nicePerc(pv_getIncValueCCFPerc(3, user))}}</td>
              <td class="ae">{{$niceMoney(pv_getIncValueCTFEur(3, user))}}</td>
              <td class="ae">{{$niceMoney(pv_getIncValueCTPEur(3, user))}}</td>
            </tr>
            <tr>
              <td class="as">{{cea.year+1}} 🕰</td>
              <td class="ae">{{$niceMoney(pv_getIncValueSoldEur((cea.year+1), user))}}</td>
              <td class="ae">{{$niceMoney(pv_getIncValueCCFEur((cea.year+1), user))}}</td>
              <td class="ae">{{$nicePerc(pv_getIncValueCCFPerc((cea.year+1), user))}}</td>
              <td class="ae">{{$niceMoney(pv_getIncValueCTFEur((cea.year+1), user))}}</td>
              <td class="ae">{{$niceMoney(pv_getIncValueCTPEur((cea.year+1), user))}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12 col-md-4">
        <div class="co-inc-c-wrapper" :class="user ? 'wu' : ''">
          <p>Aggregato copertura costi fissi</p>
          <div class="i-co-inc-c-wrapper" ref="inc_pv_chart"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as helperFunctions from "../../helpers/helpers"

  import * as am5 from '@amcharts/amcharts5'
  import * as am5percent from '@amcharts/amcharts5/percent'
  import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

  export default {
    props: {
      cea: Object,
      ceps: Array,
      user: Number,
      eas: Array,
      pls: Array,
    },

    data(){
      return {
        inc_pv_chart: null,
      }
    },

    mounted(){
      this.$nextTick(() => {
        this.renderChart();
      });
    },

    methods: {
      ...helperFunctions,

      getUser(user_id){
        var obj = {id: null, label: '-'};
        var ea_results = this.eas.filter((item) => { return item.value==user_id; });
        if(ea_results && ea_results.length>0){
          obj = ea_results[0];
        }
        var pl_results = this.pls.filter((item) => { return item.value==user_id; });
        if(pl_results && pl_results.length>0){
          obj = pl_results[0];
        }
        return obj;
      },

      filterCeps(obj=null, user=null, year=null, conv_q=null){
        var flag = true;
        if(obj.status!='consolidato'){
          flag = false;
        }
        if(user){
          if(user!=obj.ea_id && user!=obj.pl_id){
            flag = false;
          }
        }
        if(year && obj.quote_accepted_at_year!=year){
          flag = false;
        }
        if(conv_q && obj.conv_q!=conv_q){
          flag = false;
        }
        return flag;
      },

      pv_getIncValueSoldEur(value, user){
        var eur = 0;
        switch(value){
          case (this.cea.year-1):
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, (this.cea.year-1), null);
            })
            .reduce((n, {calcd_prezzo_vendita_tot_eur}) => n+calcd_prezzo_vendita_tot_eur, 0);
            break;
          case 1:
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, this.cea.year, 1);
            })
            .reduce((n, {calcd_prezzo_vendita_tot_eur}) => n+calcd_prezzo_vendita_tot_eur, 0);
            break;
          case 2:
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, this.cea.year, 2);
            })
            .reduce((n, {calcd_prezzo_vendita_tot_eur}) => n+calcd_prezzo_vendita_tot_eur, 0);
            break;
          case 3:
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, this.cea.year, 3);
            })
            .reduce((n, {calcd_prezzo_vendita_tot_eur}) => n+calcd_prezzo_vendita_tot_eur, 0);
            break;
          case (this.cea.year+1):
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, (this.cea.year+1), null);
            })
            .reduce((n, {calcd_prezzo_vendita_tot_eur}) => n+calcd_prezzo_vendita_tot_eur, 0);
            break;
          default:break;
        }
        return eur;
      },

      pv_getIncValueCCFEur(value, user){
        var eur = 0;
        switch(value){
          case (this.cea.year-1):
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, (this.cea.year-1), null);
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur, calcd_csp_fissi_mol_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur+calcd_csp_fissi_mol_tot_eur, 0);
            break;
          case 1:
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, this.cea.year, 1);
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur, calcd_csp_fissi_mol_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur+calcd_csp_fissi_mol_tot_eur, 0);
            break;
          case 2:
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, this.cea.year, 2);
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur, calcd_csp_fissi_mol_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur+calcd_csp_fissi_mol_tot_eur, 0);
            break;
          case 3:
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, this.cea.year, 3);
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur, calcd_csp_fissi_mol_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur+calcd_csp_fissi_mol_tot_eur, 0);
            break;
          case (this.cea.year+1):
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, (this.cea.year+1), null);
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur, calcd_csp_fissi_mol_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur+calcd_csp_fissi_mol_tot_eur, 0);
            break;
          default:break;
        }
        return eur;
      },

      pv_getIncValueCCFPerc(value, user){
        var perc = this.pv_getIncValueCCFEur(value, user)!=0 ? (this.pv_getIncValueCCFEur(value, user)/this.pv_getIncValueSoldEur(value, user)*100) : 0;
        return perc;
      },

      pv_getIncValueCTFEur(value, user){
        var eur = 0;
        switch(value){
          case (this.cea.year-1):
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, (this.cea.year-1), null);
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur, 0);
            break;
          case 1:
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, this.cea.year, 1);
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur, 0);
            break;
          case 2:
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, this.cea.year, 2);
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur, 0);
            break;
          case 3:
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, this.cea.year, 3);
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur, 0);
            break;
          case (this.cea.year+1):
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, (this.cea.year+1), null);
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur, 0);
            break;
          default:break;
        }
        return eur;
      },

      pv_getIncValueCTPEur(value, user){
        var eur = 0;
        switch(value){
          case (this.cea.year-1):
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, (this.cea.year-1), null);
            })
            .reduce((n, {calcd_csp_var_prod_int_piva_tot_eur}) => n+calcd_csp_var_prod_int_piva_tot_eur, 0);
            break;
          case 1:
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, this.cea.year, 1);
            })
            .reduce((n, {calcd_csp_var_prod_int_piva_tot_eur}) => n+calcd_csp_var_prod_int_piva_tot_eur, 0);
            break;
          case 2:
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, this.cea.year, 2);
            })
            .reduce((n, {calcd_csp_var_prod_int_piva_tot_eur}) => n+calcd_csp_var_prod_int_piva_tot_eur, 0);
            break;
          case 3:
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, this.cea.year, 3);
            })
            .reduce((n, {calcd_csp_var_prod_int_piva_tot_eur}) => n+calcd_csp_var_prod_int_piva_tot_eur, 0);
            break;
          case (this.cea.year+1):
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, (this.cea.year+1), null);
            })
            .reduce((n, {calcd_csp_var_prod_int_piva_tot_eur}) => n+calcd_csp_var_prod_int_piva_tot_eur, 0);
            break;
          default:break;
        }
        return eur;
      },

      renderChart(){
        if(this.inc_pv_chart){
          this.inc_pv_chart.dispose();
        }
        var inc_pv_chart = am5.Root.new(this.$refs.inc_pv_chart);
        inc_pv_chart.setThemes([am5themes_Animated.new(inc_pv_chart)]);

        var chart = inc_pv_chart.container.children.push(
          am5percent.PieChart.new(inc_pv_chart, {
            radius: am5.percent(50),
            innerRadius: am5.percent(60)
          })
        );

        // Define data
        var data = [
          {
            category: (this.cea.year-1)+' 🕰',
            value: this.pv_getIncValueCCFEur((this.cea.year-1), this.user),
          },
          {
            category: 'Q1 🌸',
            value: this.pv_getIncValueCCFEur(1, this.user),
          },
          {
            category: 'Q2 ☀️',
            value: this.pv_getIncValueCCFEur(2, this.user),
          },
          {
            category: 'Q3 ❄️',
            value: this.pv_getIncValueCCFEur(3, this.user),
          },
          {
            category: (this.cea.year+1)+' 🕰',
            value: this.pv_getIncValueCCFEur((this.cea.year+1), this.user),
          },
        ];

        var series = chart.series.push(
          am5percent.PieSeries.new(inc_pv_chart, {
            name: 'Series',
            categoryField: 'category',
            valueField: 'value',
          })
        );

        series.get('colors').set('colors', [
          am5.color(0x981869),
          am5.color(0xFF9300),
          am5.color(0x8BD0EF),
          am5.color(0x9B49ED),
          am5.color(0x0BC404)
        ]);

        series.data.setAll(data);

        series.labels.template.setAll({
          maxWidth: 170,
          textAlign: 'left',
          oversizedBehavior: 'wrap',
        });

        this.inc_pv_chart = inc_pv_chart;
      },
    }
  }
</script>