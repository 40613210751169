<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-8">
        <template v-if="user">
          <h4>{{getUser(user).label}}</h4>
        </template>
        <table>
          <thead>
            <tr>
              <th class="as">Tipologia</th>
              <th class="ae">Venduto</th>
              <th class="ae">CCF (€)</th>
              <th class="ae">CCF (%)</th>
              <th class="ae">Copertura team fisso (€)</th>
              <th class="ae">Copertura team p.iva (€)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="as">New ⚡️</td>
              <td class="ae">{{$niceMoney(tv_getIncValueSoldEur('new', user))}}</td>
              <td class="ae">{{$niceMoney(tv_getIncValueCCFEur('new', user))}}</td>
              <td class="ae">{{$nicePerc(tv_getIncValueCCFPerc('new', user))}}</td>
              <td class="ae">{{$niceMoney(tv_getIncValueCTFEur('new', user))}}</td>
              <td class="ae">{{$niceMoney(tv_getIncValueCTPEur('new', user))}}</td>
            </tr>
            <tr>
              <td class="as">Cross 🚀</td>
              <td class="ae">{{$niceMoney(tv_getIncValueSoldEur('cross', user))}}</td>
              <td class="ae">{{$niceMoney(tv_getIncValueCCFEur('cross', user))}}</td>
              <td class="ae">{{$nicePerc(tv_getIncValueCCFPerc('cross', user))}}</td>
              <td class="ae">{{$niceMoney(tv_getIncValueCTFEur('cross', user))}}</td>
              <td class="ae">{{$niceMoney(tv_getIncValueCTPEur('cross', user))}}</td>
            </tr>
            <tr>
              <td class="as">Up 💘</td>
              <td class="ae">{{$niceMoney(tv_getIncValueSoldEur('up', user))}}</td>
              <td class="ae">{{$niceMoney(tv_getIncValueCCFEur('up', user))}}</td>
              <td class="ae">{{$nicePerc(tv_getIncValueCCFPerc('up', user))}}</td>
              <td class="ae">{{$niceMoney(tv_getIncValueCTFEur('up', user))}}</td>
              <td class="ae">{{$niceMoney(tv_getIncValueCTPEur('up', user))}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12 col-md-4">
        <div class="co-inc-c-wrapper" :class="user ? 'wu' : ''">
          <p>Aggregato copertura costi fissi</p>
          <div class="i-co-inc-c-wrapper" ref="inc_tv_chart"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as helperFunctions from "../../helpers/helpers"

  import * as am5 from '@amcharts/amcharts5'
  import * as am5percent from '@amcharts/amcharts5/percent'
  import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

  export default {
    props: {
      cea: Object,
      ceps: Array,
      user: Number,
      eas: Array,
      pls: Array,
    },

    data(){
      return {
        inc_tv_chart: null,
      }
    },

    mounted(){
      this.$nextTick(() => {
        this.renderChart();
      });
    },

    methods: {
      ...helperFunctions,

      getUser(user_id){
        var obj = {id: null, label: '-'};
        var ea_results = this.eas.filter((item) => { return item.value==user_id; });
        if(ea_results && ea_results.length>0){
          obj = ea_results[0];
        }
        var pl_results = this.pls.filter((item) => { return item.value==user_id; });
        if(pl_results && pl_results.length>0){
          obj = pl_results[0];
        }
        return obj;
      },

      filterCeps(obj=null, user=null, grade=null){
        var flag = true;
        if(obj.status!='consolidato'){
          flag = false;
        }
        if(user){
          if(user!=obj.ea_id && user!=obj.pl_id){
            flag = false;
          }
        }
        if(grade && obj.grade!=grade){
          flag = false;
        }
        return flag;
      },

      tv_getIncValueSoldEur(value, user){
        var eur = 0;
        switch(value){
          case 'new':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'new_sell');
            })
            .reduce((n, {calcd_prezzo_vendita_tot_eur}) => n+calcd_prezzo_vendita_tot_eur, 0);
            break;
          case 'cross':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'cross_sell');
            })
            .reduce((n, {calcd_prezzo_vendita_tot_eur}) => n+calcd_prezzo_vendita_tot_eur, 0);
            break;
          case 'up':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'up_sell');
            })
            .reduce((n, {calcd_prezzo_vendita_tot_eur}) => n+calcd_prezzo_vendita_tot_eur, 0);
            break;
          default:break;
        }
        return eur;
      },

      tv_getIncValueCCFEur(value, user){
        var eur = 0;
        switch(value){
          case 'new':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'new_sell');
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur, calcd_csp_fissi_mol_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur+calcd_csp_fissi_mol_tot_eur, 0);
            break;
          case 'cross':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'cross_sell');
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur, calcd_csp_fissi_mol_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur+calcd_csp_fissi_mol_tot_eur, 0);
            break;
          case 'up':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'up_sell');
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur, calcd_csp_fissi_mol_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur+calcd_csp_fissi_mol_tot_eur, 0);
            break;
          default:break;
        }
        return eur;
      },

      tv_getIncValueCCFPerc(value, user){
        var perc = this.tv_getIncValueCCFEur(value, user)!=0 ? (this.tv_getIncValueCCFEur(value, user)/this.tv_getIncValueSoldEur(value, user)*100) : 0;
        return perc;
      },

      tv_getIncValueCTFEur(value, user){
        var eur = 0;
        switch(value){
          case 'new':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'new_sell');
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur, 0);
            break;
          case 'cross':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'cross_sell');
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur, 0);
            break;
          case 'up':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'up_sell');
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur, 0);
            break;
          default:break;
        }
        return eur;
      },

      tv_getIncValueCTPEur(value, user){
        var eur = 0;
        switch(value){
          case 'new':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'new_sell');
            })
            .reduce((n, {calcd_csp_var_prod_int_piva_tot_eur}) => n+calcd_csp_var_prod_int_piva_tot_eur, 0);
            break;
          case 'cross':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'cross_sell');
            })
            .reduce((n, {calcd_csp_var_prod_int_piva_tot_eur}) => n+calcd_csp_var_prod_int_piva_tot_eur, 0);
            break;
          case 'up':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'up_sell');
            })
            .reduce((n, {calcd_csp_var_prod_int_piva_tot_eur}) => n+calcd_csp_var_prod_int_piva_tot_eur, 0);
            break;
          default:break;
        }
        return eur;
      },

      renderChart(){
        if(this.inc_tv_chart){
          this.inc_tv_chart.dispose();
        }
        var inc_tv_chart = am5.Root.new(this.$refs.inc_tv_chart);
        inc_tv_chart.setThemes([am5themes_Animated.new(inc_tv_chart)]);

        var chart = inc_tv_chart.container.children.push(
          am5percent.PieChart.new(inc_tv_chart, {
            radius: am5.percent(50),
            innerRadius: am5.percent(60)
          })
        );

        // Define data
        var data = [
          {
            category: 'New ⚡️',
            value: this.tv_getIncValueCCFEur('new', this.user),
          },
          {
            category: 'Cross 🚀',
            value: this.tv_getIncValueCCFEur('cross', this.user),
          },
          {
            category: 'Up 💘',
            value: this.tv_getIncValueCCFEur('up', this.user),
          },
        ];

        var series = chart.series.push(
          am5percent.PieSeries.new(inc_tv_chart, {
            name: 'Series',
            categoryField: 'category',
            valueField: 'value',
          })
        );

        series.get('colors').set('colors', [
          am5.color(0x981869),
          am5.color(0xFF9300),
          am5.color(0x8BD0EF)
        ]);

        series.data.setAll(data);

        series.labels.template.setAll({
          maxWidth: 170,
          textAlign: 'left',
          oversizedBehavior: 'wrap',
        });

        this.inc_tv_chart = inc_tv_chart;
      },
    }
  }
</script>