<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="co-h-box-wrapper">
          <div class="row h-wrapper">
            <div class="col-12 col-md-6">
              <p>Consuntivo di bilancio</p>
            </div>
            <div class="col-12 col-md-6">
              <span class="value" :class="calcd_cons_tot_eur>=0 ? '' : 'neg'">{{$niceMoney(calcd_cons_tot_eur)}}</span>
            </div>
          </div>
          <div class="row s-wrapper">
            <div class="col-12 col-md-6">
              <span>Uscite</span>
            </div>
            <div class="col-12 col-md-6">
              <span>Entrate</span>
            </div>
          </div>
          <div class="row c-wrapper">
            <div class="col-12 col-md-6">
              <div class="out-wrapper">
                <div>
                  <span class="top-value">{{$niceMoney(calcd_tot_ext_eur)}}</span>
                  <div class="l-wrapper">
                    <span class="c-label">Costi</span>
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <p>Fissi da bilancino</p>
                      </div>
                      <div class="col-12 col-md-6">
                        <span class="value">{{$niceMoney(comp_bi_values.calcd_fixed_costs)}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <p>Variabili interni</p>
                      </div>
                      <div class="col-12 col-md-6">
                        <span class="value">{{$niceMoney(calcd_var_int_eur)}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <p>Variabili esterni</p>
                      </div>
                      <div class="col-12 col-md-6">
                        <span class="value">{{$niceMoney(calcd_var_ext_eur)}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="in-wrapper">
                <div>
                  <span class="top-value">{{$niceMoney(calcd_tot_ent_eur)}}</span>
                  <div class="l-wrapper">
                    <span class="c-label">Costi</span>
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <p>Fissi diretti (team)</p>
                      </div>
                      <div class="col-12 col-md-6">
                        <span class="value">{{$niceMoney(calcd_fix_dir_eur)}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <p>Fissi indiretti</p>
                      </div>
                      <div class="col-12 col-md-6">
                        <span class="value">{{$niceMoney(calcd_fix_ind_eur)}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <p>Variabili (int. ed est.)</p>
                      </div>
                      <div class="col-12 col-md-6">
                        <span class="value">{{$niceMoney(calcd_int_ext_eur)}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <p class="section-ot">Consuntivo di bilancio</p>
        <div class="co-co-box-wrapper">
          <div class="heading green">
            <div class="row">
              <div class="col-12 col-md-8">
                <p>Venduto nell’anno</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value">{{$niceMoney(calcd_sold_tot_eur)}}</span>
              </div>
            </div>
          </div>
          <div class="rows-wrapper green">
            <div class="row">
              <div class="col-12 col-md-8">
                <p>Imponibile competenza {{cea.year}}</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value">{{$niceMoney(calcd_cons_impon_eur)}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-8">
                <p>Incidenza vendite {{cea.year-1}} <span>(incluse nella competenza {{cea.year}})</span></p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value">{{$niceMoney(calcd_inc_sales_prev_year)}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-8">
                <p>Incidenza vendite {{cea.year+1}} <span>(escluse dalla competenza {{cea.year}})</span></p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value">{{$niceMoney(calcd_inc_sales_next_year)}}</span>
              </div>
            </div>
          </div>
          <div class="spacer"></div>
          <div class="heading purple">
            <div class="row">
              <div class="col-12 col-md-8">
                <p>Fatturato nell’anno</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value">{{$niceMoney(calcd_bill_tot_eur)}}</span>
              </div>
            </div>
          </div>
          <div class="rows-wrapper purple">
            <div class="row">
              <div class="col-12 col-md-8">
                <p>Fatturato a oggi</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value">{{$niceMoney(calcd_bill_today_eur)}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-8">
                <p>Da fatturare nel {{cea.year+1}}</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value">{{$niceMoney(calcd_bill_next_year_eur)}}</span>
              </div>
            </div>
          </div>
          <div class="spacer"></div>
          <div class="heading purple">
            <div class="row">
              <div class="col-12 col-md-8">
                <p>Incassato nell’anno</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value">{{$niceMoney(calcd_m_collected_tot_eur)}}</span>
              </div>
            </div>
          </div>
          <div class="rows-wrapper purple">
            <div class="row">
              <div class="col-12 col-md-8">
                <p>Incasso a oggi</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value">{{$niceMoney(calcd_m_collected_today_eur)}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <p class="section-ot">Andamento delle vendite</p>
        <div class="co-an-box-wrapper">
          <div class="t-heading">
            <div class="row">
              <div class="col-12 col-md-6">
                <p>Grado</p>
              </div>
              <div class="col-12 col-md-3">
                <p class="te">Imponibile</p>
              </div>
              <div class="col-12 col-md-3">
                <p class="te">Copertura costi fissi</p>
              </div>
            </div>
          </div>
          <div class="heading green">
            <div class="row">
              <div class="col-12 col-md-6">
                <p>Offerte consolidate <span>(commesse competenza {{cea.year}})</span></p>
              </div>
              <div class="col-12 col-md-3">
                <span class="value">{{$niceMoney(calcd_cons_impon_eur)}}</span>
              </div>
              <div class="col-12 col-md-3">
                <span class="value">{{$nicePerc(calcd_cons_impon_perc)}}</span>
              </div>
            </div>
          </div>
          <div class="heading orange">
            <div class="row">
              <div class="col-12 col-md-6">
                <p>Offerte in trattativa</p>
              </div>
              <div class="col-12 col-md-3">
                <span class="value">{{$niceMoney(calcd_trat_impon_eur)}}</span>
              </div>
              <div class="col-12 col-md-3">
                <span class="value">{{$nicePerc(calcd_trat_impon_perc)}}</span>
              </div>
            </div>
          </div>
          <div class="heading light-blue">
            <div class="row">
              <div class="col-12 col-md-6">
                <p>Offerte interessate</p>
              </div>
              <div class="col-12 col-md-3">
                <span class="value">{{$niceMoney(calcd_inte_impon_eur)}}</span>
              </div>
              <div class="col-12 col-md-3">
                <span class="value">{{$nicePerc(calcd_inte_impon_perc)}}</span>
              </div>
            </div>
          </div>
          <div class="heading black nobb">
            <div class="row">
              <div class="col-12 col-md-6">
                <p>Offerte scartate</p>
              </div>
              <div class="col-12 col-md-3">
                <span class="value">{{$niceMoney(calcd_pers_impon_eur)}}</span>
              </div>
              <div class="col-12 col-md-3">
                <span class="value">{{$nicePerc(calcd_pers_impon_perc)}}</span>
              </div>
            </div>
          </div>
          <div class="spacer"></div>
          <div class="heading purple">
            <div class="row">
              <div class="col-12 col-md-6">
                <p>Sforzo commerciale complessivo</p>
              </div>
              <div class="col-12 col-md-3">
                <span class="value">{{$niceMoney(calcd_comm_effort_tot_eur)}}</span>
              </div>
              <div class="col-12 col-md-3"></div>
            </div>
          </div>
          <div class="heading purple nobb">
            <div class="row">
              <div class="col-12 col-md-6">
                <p>Tasso di conversione</p>
              </div>
              <div class="col-12 col-md-3">
                <span class="value">{{$nicePerc(calcd_conv_rate)}}</span>
              </div>
              <div class="col-12 col-md-3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <p class="section-ot">Dettaglio del consolidato</p>
        <div class="co-det-box-wrapper">
          <div class="heading green">
            <div class="row">
              <div class="col-12 col-md-8">
                <p>Valore medio tariffa giornaliera</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value">{{$niceMoney(calcd_det_cons_avg_daily_rate_eur)}}</span>
              </div>
            </div>
          </div>
          <div class="heading green">
            <div class="row">
              <div class="col-12 col-md-8">
                <p>Ore team a stipendio fisso</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value">{{$niceNumber(calcd_det_cons_team_stip_h)}} h</span>
              </div>
            </div>
          </div>
          <div class="heading green nobb">
            <div class="row">
              <div class="col-12 col-md-8">
                <p>Ore team a p.iva</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value">{{$niceNumber(calcd_det_cons_team_piva_h)}} h</span>
              </div>
            </div>
          </div>
          <div class="spacer"></div>
          <div class="t-heading">
            <div class="row">
              <div class="col-12 col-md-10">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <p></p>
                  </div>
                  <div class="col-12 col-md-3">
                    <p class="te">€</p>
                  </div>
                  <div class="col-12 col-md-3">
                    <p class="te">%</p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-2">
                <p class="te">%</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-10">
              <div class="sm-heading green">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <p>Produzione interna</p>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$niceMoney(calcd_det_cons_prod_int_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$nicePerc(calcd_det_cons_prod_int_perc)}}</span>
                  </div>
                </div>
              </div>
              <div class="sm-heading orange">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <p>MOL</p>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$niceMoney(calcd_det_cons_mol_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$nicePerc(calcd_det_cons_mol_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-2">
              <div class="bv-wrapper wbb">
                <span class="b-value">{{$nicePerc(calcd_det_cons_t_tot_perc)}}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-10">
              <div class="sm-heading purple">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <p>Segnalatori</p>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$niceMoney(calcd_det_cons_segn_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$nicePerc(calcd_det_cons_segn_perc)}}</span>
                  </div>
                </div>
              </div>
              <div class="sm-heading light-blue">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <p>Executive Advisor</p>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$niceMoney(calcd_det_cons_ea_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$nicePerc(calcd_det_cons_ea_perc)}}</span>
                  </div>
                </div>
              </div>
              <div class="sm-heading green">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <p>Produzione interna P.IVA</p>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$niceMoney(calcd_det_cons_prod_int_piva_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$nicePerc(calcd_det_cons_prod_int_piva_perc)}}</span>
                  </div>
                </div>
              </div>
              <div class="sm-heading green">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <p>Produzione esterna P.IVA</p>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$niceMoney(calcd_det_cons_prod_ext_piva_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$nicePerc(calcd_det_cons_prod_ext_piva_perc)}}</span>
                  </div>
                </div>
              </div>
              <div class="sm-heading green nobb">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <p>Altri costi di produzione esterna</p>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$niceMoney(calcd_det_cons_oth_prod_ext_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$nicePerc(calcd_det_cons_oth_prod_ext_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-2">
              <div class="bv-wrapper">
                <span class="b-value">{{$nicePerc(calcd_det_cons_b_tot_perc)}}</span>
              </div>
            </div>
          </div>
          <div class="spacer"></div>
          <div class="sm-heading purple">
            <div class="row">
              <div class="col-12 col-md-6">
                <p>Costi commerciali</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value sm">{{$niceMoney(calcd_det_cons_comm_cost_eur)}}</span>
              </div>
              <div class="col-12 col-md-2">
                <span class="value">{{$nicePerc(calcd_det_cons_comm_cost_perc)}}</span>
              </div>
            </div>
          </div>
          <div class="sm-heading light-blue">
            <div class="row">
              <div class="col-12 col-md-6">
                <p>Costi di direzione</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value sm">{{$niceMoney(calcd_det_cons_dir_cost_eur)}}</span>
              </div>
              <div class="col-12 col-md-2">
                <span class="value">{{$nicePerc(calcd_det_cons_dir_cost_perc)}}</span>
              </div>
            </div>
          </div>
          <div class="sm-heading green">
            <div class="row">
              <div class="col-12 col-md-6">
                <p>Costi di produzione</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value sm">{{$niceMoney(calcd_det_cons_prod_cost_eur)}}</span>
              </div>
              <div class="col-12 col-md-2">
                <span class="value">{{$nicePerc(calcd_det_cons_prod_cost_perc)}}</span>
              </div>
            </div>
          </div>
          <div class="sm-heading orange nobb">
            <div class="row">
              <div class="col-12 col-md-6">
                <p>MOL</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value sm">{{$niceMoney(calcd_det_cons_mol_cost_eur)}}</span>
              </div>
              <div class="col-12 col-md-2">
                <span class="value">{{$nicePerc(calcd_det_cons_mol_cost_perc)}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <p class="section-ot">Dettaglio della trattativa</p>
        <div class="co-det-box-wrapper">
          <div class="heading green">
            <div class="row">
              <div class="col-12 col-md-8">
                <p>Valore medio tariffa giornaliera</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value">{{$niceMoney(calcd_det_trat_avg_daily_rate_eur)}}</span>
              </div>
            </div>
          </div>
          <div class="heading green">
            <div class="row">
              <div class="col-12 col-md-8">
                <p>Ore team a stipendio fisso</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value">{{$niceNumber(calcd_det_trat_team_stip_h)}} h</span>
              </div>
            </div>
          </div>
          <div class="heading green nobb">
            <div class="row">
              <div class="col-12 col-md-8">
                <p>Ore team a p.iva</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value">{{$niceNumber(calcd_det_trat_team_piva_h)}} h</span>
              </div>
            </div>
          </div>
          <div class="spacer"></div>
          <div class="t-heading">
            <div class="row">
              <div class="col-12 col-md-10">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <p></p>
                  </div>
                  <div class="col-12 col-md-3">
                    <p class="te">%</p>
                  </div>
                  <div class="col-12 col-md-3">
                    <p class="te">€</p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-2">
                <p class="te">%</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-10">
              <div class="sm-heading green">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <p>Produzione interna</p>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$nicePerc(calcd_det_trat_prod_int_perc)}}</span>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$niceMoney(calcd_det_trat_prod_int_eur)}}</span>
                  </div>
                </div>
              </div>
              <div class="sm-heading orange">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <p>MOL</p>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$nicePerc(calcd_det_trat_mol_perc)}}</span>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$niceMoney(calcd_det_trat_mol_eur)}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-2">
              <div class="bv-wrapper wbb">
                <span class="b-value">{{$nicePerc(calcd_det_trat_t_tot_perc)}}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-10">
              <div class="sm-heading purple">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <p>Segnalatori</p>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm"></span>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$niceMoney(calcd_det_trat_segn_eur)}}</span>
                  </div>
                </div>
              </div>
              <div class="sm-heading light-blue">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <p>Executive Advisor</p>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm"></span>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$niceMoney(calcd_det_trat_ea_eur)}}</span>
                  </div>
                </div>
              </div>
              <div class="sm-heading green">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <p>Produzione interna P.IVA</p>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm"></span>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$niceMoney(calcd_det_trat_prod_int_piva_eur)}}</span>
                  </div>
                </div>
              </div>
              <div class="sm-heading green">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <p>Produzione esterna P.IVA</p>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm"></span>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$niceMoney(calcd_det_trat_prod_ext_piva_eur)}}</span>
                  </div>
                </div>
              </div>
              <div class="sm-heading green nobb">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <p>Altri costi di produzione esterna</p>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm"></span>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="value sm">{{$niceMoney(calcd_det_trat_oth_prod_ext_eur)}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-2">
              <div class="bv-wrapper">
                <span class="b-value">{{$nicePerc(calcd_det_trat_b_tot_perc)}}</span>
              </div>
            </div>
          </div>
          <div class="spacer"></div>
          <div class="sm-heading purple">
            <div class="row">
              <div class="col-12 col-md-6">
                <p>Costi commerciali</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value sm">{{$niceMoney(calcd_det_trat_comm_cost_eur)}}</span>
              </div>
              <div class="col-12 col-md-2">
                <span class="value">{{$nicePerc(calcd_det_trat_comm_cost_perc)}}</span>
              </div>
            </div>
          </div>
          <div class="sm-heading light-blue">
            <div class="row">
              <div class="col-12 col-md-6">
                <p>Costi di direzione</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value sm">{{$niceMoney(calcd_det_trat_dir_cost_eur)}}</span>
              </div>
              <div class="col-12 col-md-2">
                <span class="value">{{$nicePerc(calcd_det_trat_dir_cost_perc)}}</span>
              </div>
            </div>
          </div>
          <div class="sm-heading green">
            <div class="row">
              <div class="col-12 col-md-6">
                <p>Costi di produzione</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value sm">{{$niceMoney(calcd_det_trat_prod_cost_eur)}}</span>
              </div>
              <div class="col-12 col-md-2">
                <span class="value">{{$nicePerc(calcd_det_trat_prod_cost_perc)}}</span>
              </div>
            </div>
          </div>
          <div class="sm-heading orange nobb">
            <div class="row">
              <div class="col-12 col-md-6">
                <p>MOL</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value sm">{{$niceMoney(calcd_det_trat_mol_cost_eur)}}</span>
              </div>
              <div class="col-12 col-md-2">
                <span class="value">{{$nicePerc(calcd_det_trat_mol_cost_perc)}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="section-ot">Visualizzazioni incidenze</p>
        <div class="inc-people-filters-wrapper">
          <template v-for="ea in eas">
            <span :class="selected_eas.includes(ea.value) ? 'active' : ''" @click="toggleSelectedEA(ea.value)">{{ea.label}}</span>
          </template>
          <template v-for="pl in pls">
            <span :class="selected_pls.includes(pl.value) ? 'active' : ''" @click="toggleSelectedPL(pl.value)">{{pl.label}}</span>
          </template>
        </div>
        <div class="inc-view-filters-wrapper">
          <span :class="selected_view=='pv' ? 'active' : ''" @click="selectView('pv')">Periodo di vendita</span>
          <span :class="selected_view=='tv' ? 'active' : ''" @click="selectView('tv')">Tipologia di vendita</span>
          <span :class="selected_view=='to' ? 'active' : ''" @click="selectView('to')">Tipologia di offerta</span>
          <span :class="selected_view=='cl' ? 'active' : ''" @click="selectView('cl')">Clienti</span>
          <span :class="selected_view=='ol' ? 'active' : ''" @click="selectView('ol')">Owner lead</span>
        </div>
        <template v-if="selected_view=='pv'">
          <div class="inc-table-wrapper">
            <template v-if="selected_eas.length==0 && selected_pls.length==0">
              <cea-inc-pv :key="'pv_null'" :cea="cea" :ceps="ceps" :user="null" :eas="eas" :pls="pls"></cea-inc-pv>
            </template>
            <template v-else>
              <template v-for="user in selected_eas" :key="'pv_'+user">
                <cea-inc-pv :cea="cea" :ceps="ceps" :user="user" :eas="eas" :pls="pls"></cea-inc-pv>
              </template>
              <template v-for="user in selected_pls" :key="'pv_'+user">
                <cea-inc-pv :cea="cea" :ceps="ceps" :user="user" :eas="eas" :pls="pls"></cea-inc-pv>
              </template>
            </template>
          </div>
        </template>
        <template v-if="selected_view=='tv'">
          <div class="inc-table-wrapper">
            <template v-if="selected_eas.length==0 && selected_pls.length==0">
              <cea-inc-tv :key="'tv_null'" :cea="cea" :ceps="ceps" :user="null" :eas="eas" :pls="pls"></cea-inc-tv>
            </template>
            <template v-else>
              <template v-for="user in selected_eas" :key="'tv_'+user">
                <cea-inc-tv :cea="cea" :ceps="ceps" :user="user" :eas="eas" :pls="pls"></cea-inc-tv>
              </template>
              <template v-for="user in selected_pls" :key="'tv_'+user">
                <cea-inc-tv :cea="cea" :ceps="ceps" :user="user" :eas="eas" :pls="pls"></cea-inc-tv>
              </template>
            </template>
          </div>
        </template>
        <template v-if="selected_view=='to'">
          <div class="inc-table-wrapper">
            <template v-if="selected_eas.length==0 && selected_pls.length==0">
              <cea-inc-to :key="'to_null'" :cea="cea" :ceps="ceps" :user="null" :eas="eas" :pls="pls"></cea-inc-to>
            </template>
            <template v-else>
              <template v-for="user in selected_eas" :key="'to_'+user">
                <cea-inc-to :cea="cea" :ceps="ceps" :user="user" :eas="eas" :pls="pls"></cea-inc-to>
              </template>
              <template v-for="user in selected_pls" :key="'to_'+user">
                <cea-inc-to :cea="cea" :ceps="ceps" :user="user" :eas="eas" :pls="pls"></cea-inc-to>
              </template>
            </template>
          </div>
        </template>
        <template v-if="selected_view=='cl'">
          <div class="inc-table-wrapper">
            <template v-if="selected_eas.length==0 && selected_pls.length==0">
              <cea-inc-cl :key="'cl_null'" :cea="cea" :ceps="ceps" :user="null" :eas="eas" :pls="pls"></cea-inc-cl>
            </template>
            <template v-else>
              <template v-for="user in selected_eas" :key="'cl_'+user">
                <cea-inc-cl :cea="cea" :ceps="ceps" :user="user" :eas="eas" :pls="pls"></cea-inc-cl>
              </template>
              <template v-for="user in selected_pls" :key="'cl_'+user">
                <cea-inc-cl :cea="cea" :ceps="ceps" :user="user" :eas="eas" :pls="pls"></cea-inc-cl>
              </template>
            </template>
          </div>
        </template>
        <template v-if="selected_view=='ol'">
          <div class="inc-table-wrapper">
            <template v-if="selected_eas.length==0 && selected_pls.length==0">
              <cea-inc-ol :key="'ol_null'" :cea="cea" :ceps="ceps" :user="null" :eas="eas" :pls="pls"></cea-inc-ol>
            </template>
            <template v-else>
              <template v-for="user in selected_eas" :key="'ol_'+user">
                <cea-inc-ol :cea="cea" :ceps="ceps" :user="user" :eas="eas" :pls="pls"></cea-inc-ol>
              </template>
              <template v-for="user in selected_pls" :key="'ol_'+user">
                <cea-inc-ol :cea="cea" :ceps="ceps" :user="user" :eas="eas" :pls="pls"></cea-inc-ol>
              </template>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import * as helperFunctions from "../../helpers/helpers"

  export default {
    props: {
      cea: Object,
      ceps: Array,
      comp_bi_values: Object,
      users: Array,
    },

    data(){
      return {
        selected_eas: [],
        selected_pls: [],

        selected_view: 'pv',
      }
    },

    computed: {
      calcd_cons_tot_eur: function(){
        // Formula A2 -> =A5+D5
        return this.calcd_tot_ext_eur+this.calcd_tot_ent_eur;
      },

      calcd_tot_ext_eur: function(){
        // Formula A5 -> =A7+B7+C7
        return this.comp_bi_values.calcd_fixed_costs+this.calcd_var_int_eur+this.calcd_var_ext_eur;
      },

      calcd_var_int_eur: function(){
        // Formula B7
        return (-1)*this.ceps.filter((obj) => {
          return obj.status=='consolidato' && (obj.quote_accepted_at_year===null || obj.quote_accepted_at_year<=(this.cea.year));
        })
        .reduce((n, {calcd_csp_var_ea_tot_eur, calcd_csp_var_prod_int_piva_tot_eur}) => n+calcd_csp_var_ea_tot_eur+calcd_csp_var_prod_int_piva_tot_eur, 0);
      },

      calcd_var_ext_eur: function(){
        // Formula C7
        return (-1)*this.ceps.filter((obj) => {
          return obj.status=='consolidato' && (obj.quote_accepted_at_year===null || obj.quote_accepted_at_year<=(this.cea.year));
        })
        .reduce((n, {calcd_csp_var_prod_est_piva_tot_eur, calcd_csp_var_prod_other_est_eur, calcd_csp_var_segnal_tot_eur}) => n+calcd_csp_var_prod_est_piva_tot_eur+calcd_csp_var_prod_other_est_eur+calcd_csp_var_segnal_tot_eur, 0);
      },

      calcd_tot_ent_eur: function(){
        // Formula D5 -> =A7+B7+C7
        return this.calcd_fix_dir_eur+this.calcd_fix_ind_eur+this.calcd_int_ext_eur;
      },

      calcd_fix_dir_eur: function(){
        // Formula D7
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato' && (obj.quote_accepted_at_year===null || obj.quote_accepted_at_year<=(this.cea.year));
        })
        .reduce((n, {calcd_csp_fissi_prod_int_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur, 0);
      },

      extra_formula_calcd_trat_fix_dir_eur: function(){
        return this.ceps.filter((obj) => {
          return obj.status=='trattativa';
        })
        .reduce((n, {calcd_csp_fissi_prod_int_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur, 0);
      },

      extra_formula_calcd_inte_fix_dir_eur: function(){
        return this.ceps.filter((obj) => {
          return obj.status=='interessato';
        })
        .reduce((n, {calcd_csp_fissi_prod_int_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur, 0);
      },

      extra_formula_calcd_pers_fix_dir_eur: function(){
        return this.ceps.filter((obj) => {
          return obj.status=='perso';
        })
        .reduce((n, {calcd_csp_fissi_prod_int_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur, 0);
      },

      calcd_fix_ind_eur: function(){
        // Formula E7
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato' && (obj.quote_accepted_at_year===null || obj.quote_accepted_at_year<=(this.cea.year));
        })
        .reduce((n, {calcd_csp_fissi_mol_tot_eur}) => n+calcd_csp_fissi_mol_tot_eur, 0);
      },

      extra_formula_calcd_trat_fix_ind_eur: function(){
        return this.ceps.filter((obj) => {
          return obj.status=='trattativa';
        })
        .reduce((n, {calcd_csp_fissi_mol_tot_eur}) => n+calcd_csp_fissi_mol_tot_eur, 0);
      },

      extra_formula_calcd_inte_fix_ind_eur: function(){
        return this.ceps.filter((obj) => {
          return obj.status=='interessato';
        })
        .reduce((n, {calcd_csp_fissi_mol_tot_eur}) => n+calcd_csp_fissi_mol_tot_eur, 0);
      },

      extra_formula_calcd_pers_fix_ind_eur: function(){
        return this.ceps.filter((obj) => {
          return obj.status=='perso';
        })
        .reduce((n, {calcd_csp_fissi_mol_tot_eur}) => n+calcd_csp_fissi_mol_tot_eur, 0);
      },

      calcd_int_ext_eur: function(){
        // Formula F7 -> =-B7-C7
        return (-1)*(this.calcd_var_int_eur+this.calcd_var_ext_eur);
      },

      calcd_sold_tot_eur: function(){
        // Formula D9
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato' && (obj.quote_accepted_at_year===null || obj.quote_accepted_at_year>(this.cea.year-1));
        })
        .reduce((n, {calcd_prezzo_vendita_tot_eur}) => n+calcd_prezzo_vendita_tot_eur, 0)-this.calcd_inc_sales_prev_year;
      },

      calcd_cons_impon_eur: function(){
        // Formula D10
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato';
        })
        .reduce((n, {calcd_prezzo_vendita_tot_eur}) => n+calcd_prezzo_vendita_tot_eur, 0)-this.calcd_inc_sales_next_year;
      },

      calcd_cons_impon_perc: function(){
        // Formula F21 -> =(D7+E7)/E21
        return this.calcd_cons_impon_eur!=0 ? ((this.calcd_fix_dir_eur+this.calcd_fix_ind_eur)/this.calcd_cons_impon_eur)*100 : 0;
      },

      calcd_trat_impon_eur: function(){
        // Formula E22
        return this.ceps.filter((obj) => {
          return obj.status=='trattativa';
        })
        .reduce((n, {calcd_prezzo_vendita_tot_eur}) => n+calcd_prezzo_vendita_tot_eur, 0);
      },

      calcd_trat_impon_perc: function(){
        // Formula F22
        return this.calcd_trat_impon_eur!=0 ? ((this.extra_formula_calcd_trat_fix_dir_eur+this.extra_formula_calcd_trat_fix_ind_eur)/this.calcd_trat_impon_eur)*100 : 0;
      },

      calcd_inte_impon_eur: function(){
        // Formula E23
        return this.ceps.filter((obj) => {
          return obj.status=='interessato';
        })
        .reduce((n, {calcd_prezzo_vendita_tot_eur}) => n+calcd_prezzo_vendita_tot_eur, 0);
      },

      calcd_inte_impon_perc: function(){
        // Formula F23
        return this.calcd_inte_impon_eur!=0 ? ((this.extra_formula_calcd_inte_fix_dir_eur+this.extra_formula_calcd_inte_fix_ind_eur)/this.calcd_inte_impon_eur)*100 : 0;
      },

      calcd_pers_impon_eur: function(){
        // Formula E24
        return this.ceps.filter((obj) => {
          return obj.status=='perso';
        })
        .reduce((n, {calcd_prezzo_vendita_tot_eur}) => n+calcd_prezzo_vendita_tot_eur, 0);
      },

      calcd_pers_impon_perc: function(){
        // Formula F24
        return this.calcd_pers_impon_eur!=0 ? ((this.extra_formula_calcd_pers_fix_dir_eur+this.extra_formula_calcd_pers_fix_ind_eur)/this.calcd_pers_impon_eur)*100 : 0;
      },

      calcd_inc_sales_prev_year: function(){
        // Formula D11
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato' && obj.quote_accepted_at_year==(this.cea.year-1);
        })
        .reduce((n, {calcd_prezzo_vendita_tot_eur}) => n+calcd_prezzo_vendita_tot_eur, 0);
      },

      calcd_inc_sales_next_year: function(){
        // Formula D12
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato' && obj.quote_accepted_at_year==(this.cea.year+1);
        })
        .reduce((n, {calcd_prezzo_vendita_tot_eur}) => n+calcd_prezzo_vendita_tot_eur, 0);
      },

      calcd_bill_tot_eur: function(){
        // Formula D13
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato';
        })
        .map((obj) => {
          return {
            calcd_tot: obj.pagam_lines.filter((line) => {
              return line.in_prev_detpag_emiss_date.includes(this.cea.year);
            })
            .reduce((n, {in_prev_detpag_impon_eur}) => n+in_prev_detpag_impon_eur, 0),
          };
        })
        .reduce((n, {calcd_tot}) => n+calcd_tot, 0);
      },

      calcd_bill_today_eur: function(){
        // Formula D14
        var today = this.$niceFromattedDate(new Date());
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato';
        })
        .map((obj) => {
          return {
            calcd_tot: obj.pagam_lines.filter((line) => {
              return line.in_prev_detpag_emiss_date<=today;
            })
            .reduce((n, {in_prev_detpag_impon_eur}) => n+in_prev_detpag_impon_eur, 0),
          };
        })
        .reduce((n, {calcd_tot}) => n+calcd_tot, 0);
      },

      calcd_bill_next_year_eur: function(){
        // Formula D15
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato';
        })
        .map((obj) => {
          return {
            calcd_tot: obj.pagam_lines.filter((line) => {
              return line.in_prev_detpag_emiss_date.includes(this.cea.year+1);
            })
            .reduce((n, {in_prev_detpag_impon_eur}) => n+in_prev_detpag_impon_eur, 0),
          };
        })
        .reduce((n, {calcd_tot}) => n+calcd_tot, 0);
      },

      calcd_m_collected_tot_eur: function(){
        // Formula D16
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato';
        })
        .map((obj) => {
          return {
            calcd_tot: obj.pagam_lines.filter((line) => {
              return line.calcd_prev_detpag_incasso_prev.includes(this.cea.year);
            })
            .reduce((n, {in_prev_detpag_impon_eur}) => n+in_prev_detpag_impon_eur, 0),
          };
        })
        .reduce((n, {calcd_tot}) => n+calcd_tot, 0);
      },

      calcd_m_collected_today_eur: function(){
        // Formula D17
        var today = this.$niceFromattedDate(new Date());
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato';
        })
        .map((obj) => {
          return {
            calcd_tot: obj.pagam_lines.filter((line) => {
              return line.calcd_prev_detpag_incasso_prev<=today;
            })
            .reduce((n, {in_prev_detpag_impon_eur}) => n+in_prev_detpag_impon_eur, 0),
          };
        })
        .reduce((n, {calcd_tot}) => n+calcd_tot, 0);
      },

      calcd_comm_effort_tot_eur: function(){
        // Formula E26 -> =D9+E22+E23+E24
        return this.calcd_sold_tot_eur+this.calcd_trat_impon_eur+this.calcd_inte_impon_eur+this.calcd_pers_impon_eur;
      },

      calcd_conv_rate: function(){
        // Formula E27 -> =D9/E26
        return this.calcd_comm_effort_tot_eur!=0 ? (this.calcd_sold_tot_eur/this.calcd_comm_effort_tot_eur)*100 : 0;
      },

      calcd_det_cons_avg_daily_rate_eur: function(){
        // Formula D31
        var arr = this.ceps.filter((obj) => {
          return obj.status=='consolidato';
        })
        .map((obj) => {
          return {
            calcd_prezzo_vendita_tot_eur: obj.calcd_prezzo_vendita_tot_eur,
            calcd_25_prod_others_tot_eur: obj.calcd_25_prod_others_tot_eur,
            calcd_24_prod_media_tot_eur: obj.calcd_24_prod_media_tot_eur,
            calcd_23_prod_fornit_piva_tot_eur: obj.calcd_23_prod_fornit_piva_tot_eur,
            prod_team_stip_h_sum: obj.prod_team_stip_lines.reduce((n, {in_21_prod_team_stip_h_qty}) => n+parseFloat(in_21_prod_team_stip_h_qty), 0),
            prod_team_piva_h_sum: obj.prod_team_piva_lines.reduce((n, {in_22_prod_team_piva_h_qty}) => n+parseFloat(in_22_prod_team_piva_h_qty), 0),
          };
        })
        .map((obj) => {
          return {
            daily_rate: (obj.prod_team_stip_h_sum+obj.prod_team_piva_h_sum)!=0 ? (obj.calcd_prezzo_vendita_tot_eur-obj.calcd_25_prod_others_tot_eur-obj.calcd_24_prod_media_tot_eur-obj.calcd_23_prod_fornit_piva_tot_eur)/(obj.prod_team_stip_h_sum+obj.prod_team_piva_h_sum)*8 : 0,
          };
        });
        return arr.length!=0 ? (arr.reduce((n, {daily_rate}) => n+daily_rate, 0))/arr.length : 0;
      },

      calcd_det_cons_team_stip_h: function(){
        // Formula D32
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato' && (obj.quote_accepted_at_year===null || obj.quote_accepted_at_year<=(this.cea.year));
        })
        .reduce((n, {calcd_csp_fissi_prod_int_tot_h}) => n+parseFloat(calcd_csp_fissi_prod_int_tot_h), 0);
      },

      calcd_det_cons_team_piva_h: function(){
        // Formula D33
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato' && (obj.quote_accepted_at_year===null || obj.quote_accepted_at_year<=(this.cea.year));
        })
        .reduce((n, {calcd_csp_var_prod_piva_tot_h}) => n+parseFloat(calcd_csp_var_prod_piva_tot_h), 0);
      },

      calcd_det_cons_prod_int_eur: function(){
        // Formula D35
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato' && (obj.quote_accepted_at_year===null || obj.quote_accepted_at_year<=(this.cea.year));
        })
        .reduce((n, {calcd_csp_fissi_prod_int_tot_eur}) => n+parseFloat(calcd_csp_fissi_prod_int_tot_eur), 0);
      },

      calcd_det_cons_prod_int_perc: function(){
        // Formula E35 -> =D35/E21
        return this.calcd_cons_impon_eur!=0 ? (this.calcd_det_cons_prod_int_eur/this.calcd_cons_impon_eur)*100 : 0;
      },

      calcd_det_cons_mol_eur: function(){
        // Formula D36
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato' && (obj.quote_accepted_at_year===null || obj.quote_accepted_at_year<=(this.cea.year));
        })
        .reduce((n, {calcd_csp_fissi_mol_tot_eur}) => n+parseFloat(calcd_csp_fissi_mol_tot_eur), 0);
      },

      calcd_det_cons_mol_perc: function(){
        // Formula E36 -> =D36/E21
        return this.calcd_cons_impon_eur!=0 ? (this.calcd_det_cons_mol_eur/this.calcd_cons_impon_eur)*100 : 0;
      },

      calcd_det_cons_segn_eur: function(){
        // Formula D37
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato' && (obj.quote_accepted_at_year===null || obj.quote_accepted_at_year<=(this.cea.year));
        })
        .reduce((n, {calcd_csp_var_segnal_tot_eur}) => n+parseFloat(calcd_csp_var_segnal_tot_eur), 0);
      },

      calcd_det_cons_segn_perc: function(){
        // Formula E37 -> =D37/E21
        return this.calcd_cons_impon_eur!=0 ? (this.calcd_det_cons_segn_eur/this.calcd_cons_impon_eur)*100 : 0;
      },

      calcd_det_cons_ea_eur: function(){
        // Formula D38
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato' && (obj.quote_accepted_at_year===null || obj.quote_accepted_at_year<=(this.cea.year));
        })
        .reduce((n, {calcd_csp_var_ea_tot_eur}) => n+parseFloat(calcd_csp_var_ea_tot_eur), 0);
      },

      calcd_det_cons_ea_perc: function(){
        // Formula E38 -> =D38/E21
        return this.calcd_cons_impon_eur!=0 ? (this.calcd_det_cons_ea_eur/this.calcd_cons_impon_eur)*100 : 0;
      },

      calcd_det_cons_prod_int_piva_eur: function(){
        // Formula D39
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato' && (obj.quote_accepted_at_year===null || obj.quote_accepted_at_year<=(this.cea.year));
        })
        .reduce((n, {calcd_csp_var_prod_int_piva_tot_eur}) => n+parseFloat(calcd_csp_var_prod_int_piva_tot_eur), 0);
      },

      calcd_det_cons_prod_int_piva_perc: function(){
        // Formula E39 -> =D39/E21
        return this.calcd_cons_impon_eur!=0 ? (this.calcd_det_cons_prod_int_piva_eur/this.calcd_cons_impon_eur)*100 : 0;
      },

      calcd_det_cons_prod_ext_piva_eur: function(){
        // Formula D40
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato' && (obj.quote_accepted_at_year===null || obj.quote_accepted_at_year<=(this.cea.year));
        })
        .reduce((n, {calcd_csp_var_prod_est_piva_tot_eur}) => n+parseFloat(calcd_csp_var_prod_est_piva_tot_eur), 0);
      },

      calcd_det_cons_prod_ext_piva_perc: function(){
        // Formula E40 -> =D40/E21
        return this.calcd_cons_impon_eur!=0 ? (this.calcd_det_cons_prod_ext_piva_eur/this.calcd_cons_impon_eur)*100 : 0;
      },

      calcd_det_cons_oth_prod_ext_eur: function(){
        // Formula D41
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato' && (obj.quote_accepted_at_year===null || obj.quote_accepted_at_year<=(this.cea.year));
        })
        .reduce((n, {calcd_csp_var_prod_other_est_eur}) => n+parseFloat(calcd_csp_var_prod_other_est_eur), 0);
      },

      calcd_det_cons_oth_prod_ext_perc: function(){
        // Formula E41 -> =D41/E21
        return this.calcd_cons_impon_eur!=0 ? (this.calcd_det_cons_oth_prod_ext_eur/this.calcd_cons_impon_eur)*100 : 0;
      },

      calcd_det_cons_t_tot_perc: function(){
        // Formula F35 -> =SOMMA(E35:E36)
        return this.calcd_det_cons_prod_int_perc+this.calcd_det_cons_mol_perc;
      },

      calcd_det_cons_b_tot_perc: function(){
        // Formula F37 -> =SOMMA(E37:E41)
        return +this.calcd_det_cons_segn_perc+this.calcd_det_cons_ea_perc+this.calcd_det_cons_prod_int_piva_perc+this.calcd_det_cons_prod_ext_piva_perc+this.calcd_det_cons_oth_prod_ext_perc;
      },

      calcd_det_cons_comm_cost_eur: function(){
        // Formula E43
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato' && (obj.quote_accepted_at_year===null || obj.quote_accepted_at_year<=(this.cea.year));
        })
        .reduce((n, {calcd_01_new_sell_ext_eur, calcd_01_new_sell_oth_eur, calcd_01_new_sell_int_eur, calcd_01_upsell_int_eur, calcd_02_extra_margine_tot_eur, calcd_03_extra_margine_bonus_tot_eur}) => n+calcd_01_new_sell_ext_eur+calcd_01_new_sell_oth_eur+calcd_01_new_sell_int_eur+calcd_01_upsell_int_eur+calcd_02_extra_margine_tot_eur+calcd_03_extra_margine_bonus_tot_eur, 0);
      },

      calcd_det_cons_comm_cost_perc: function(){
        // Formula F43 -> =E43/E21
        return this.calcd_cons_impon_eur!=0 ? (this.calcd_det_cons_comm_cost_eur)/this.calcd_cons_impon_eur*100 : 0;
      },

      calcd_det_cons_dir_cost_eur: function(){
        // Formula E44
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato' && (obj.quote_accepted_at_year===null || obj.quote_accepted_at_year<=(this.cea.year));
        })
        .reduce((n, {calcd_cvb_dir_tot_eur}) => n+parseFloat(calcd_cvb_dir_tot_eur), 0);
      },

      calcd_det_cons_dir_cost_perc: function(){
        // Formula F44 -> =E44/E21
        return this.calcd_cons_impon_eur!=0 ? (this.calcd_det_cons_dir_cost_eur)/this.calcd_cons_impon_eur*100 : 0;
      },

      calcd_det_cons_prod_cost_eur: function(){
        // Formula E45
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato' && (obj.quote_accepted_at_year===null || obj.quote_accepted_at_year<=(this.cea.year));
        })
        .reduce((n, {calcd_cvb_prod_tot_eur}) => n+parseFloat(calcd_cvb_prod_tot_eur), 0);
      },

      calcd_det_cons_prod_cost_perc: function(){
        // Formula F45 -> =E45/E21
        return this.calcd_cons_impon_eur!=0 ? (this.calcd_det_cons_prod_cost_eur)/this.calcd_cons_impon_eur*100 : 0;
      },

      calcd_det_cons_mol_cost_eur: function(){
        // Formula E46
        return this.ceps.filter((obj) => {
          return obj.status=='consolidato' && (obj.quote_accepted_at_year===null || obj.quote_accepted_at_year<=(this.cea.year));
        })
        .reduce((n, {calcd_cvb_mol_tot_eur}) => n+parseFloat(calcd_cvb_mol_tot_eur), 0);
      },

      calcd_det_cons_mol_cost_perc: function(){
        // Formula F46 -> =E46/E21
        return this.calcd_cons_impon_eur!=0 ? (this.calcd_det_cons_mol_cost_eur)/this.calcd_cons_impon_eur*100 : 0;
      },

      calcd_det_trat_avg_daily_rate_eur: function(){
        // Formula D50
        var arr = this.ceps.filter((obj) => {
          return obj.status=='trattativa';
        })
        .map((obj) => {
          return {
            calcd_prezzo_vendita_tot_eur: obj.calcd_prezzo_vendita_tot_eur,
            calcd_25_prod_others_tot_eur: obj.calcd_25_prod_others_tot_eur,
            calcd_24_prod_media_tot_eur: obj.calcd_24_prod_media_tot_eur,
            calcd_23_prod_fornit_piva_tot_eur: obj.calcd_23_prod_fornit_piva_tot_eur,
            prod_team_stip_h_sum: obj.prod_team_stip_lines.reduce((n, {in_21_prod_team_stip_h_qty}) => n+parseFloat(in_21_prod_team_stip_h_qty), 0),
            prod_team_piva_h_sum: obj.prod_team_piva_lines.reduce((n, {in_22_prod_team_piva_h_qty}) => n+parseFloat(in_22_prod_team_piva_h_qty), 0),
          };
        })
        .map((obj) => {
          return {
            daily_rate: (obj.prod_team_stip_h_sum+obj.prod_team_piva_h_sum)!=0 ? (obj.calcd_prezzo_vendita_tot_eur-obj.calcd_25_prod_others_tot_eur-obj.calcd_24_prod_media_tot_eur-obj.calcd_23_prod_fornit_piva_tot_eur)/(obj.prod_team_stip_h_sum+obj.prod_team_piva_h_sum)*8 : 0,
          };
        });
        return arr.length!=0 ? (arr.reduce((n, {daily_rate}) => n+daily_rate, 0))/arr.length : 0;
      },

      calcd_det_trat_team_stip_h: function(){
        // Formula D51
        return this.ceps.filter((obj) => {
          return obj.status=='trattativa';
        })
        .reduce((n, {calcd_csp_fissi_prod_int_tot_h}) => n+parseFloat(calcd_csp_fissi_prod_int_tot_h), 0);
      },

      calcd_det_trat_team_piva_h: function(){
        // Formula D52
        return this.ceps.filter((obj) => {
          return obj.status=='trattativa';
        })
        .reduce((n, {calcd_csp_var_prod_piva_tot_h}) => n+parseFloat(calcd_csp_var_prod_piva_tot_h), 0);
      },

      calcd_det_trat_prod_int_eur: function(){
        // Formula E54
        return this.ceps.filter((obj) => {
          return obj.status=='trattativa';
        })
        .reduce((n, {calcd_csp_fissi_prod_int_tot_eur}) => n+parseFloat(calcd_csp_fissi_prod_int_tot_eur), 0);
      },

      calcd_det_trat_prod_int_perc: function(){
        // Formula D54 -> =E54/E22
        return this.calcd_trat_impon_eur!=0 ? (this.calcd_det_trat_prod_int_eur/this.calcd_trat_impon_eur)*100 : 0;
      },

      calcd_det_trat_mol_eur: function(){
        // Formula E55
        return this.ceps.filter((obj) => {
          return obj.status=='trattativa';
        })
        .reduce((n, {calcd_csp_fissi_mol_tot_eur}) => n+parseFloat(calcd_csp_fissi_mol_tot_eur), 0);
      },

      calcd_det_trat_mol_perc: function(){
        // Formula D55 -> =E55/E22
        return this.calcd_trat_impon_eur!=0 ? (this.calcd_det_trat_mol_eur/this.calcd_trat_impon_eur)*100 : 0;
      },

      calcd_det_trat_segn_eur: function(){
        // Formula E56
        return this.ceps.filter((obj) => {
          return obj.status=='trattativa';
        })
        .reduce((n, {calcd_csp_var_segnal_tot_eur}) => n+parseFloat(calcd_csp_var_segnal_tot_eur), 0);
      },

      calcd_det_trat_ea_eur: function(){
        // Formula E57
        return this.ceps.filter((obj) => {
          return obj.status=='trattativa';
        })
        .reduce((n, {calcd_csp_var_ea_tot_eur}) => n+parseFloat(calcd_csp_var_ea_tot_eur), 0);
      },

      calcd_det_trat_prod_int_piva_eur: function(){
        // Formula E58
        return this.ceps.filter((obj) => {
          return obj.status=='trattativa';
        })
        .reduce((n, {calcd_csp_var_prod_int_piva_tot_eur}) => n+parseFloat(calcd_csp_var_prod_int_piva_tot_eur), 0);
      },

      calcd_det_trat_prod_ext_piva_eur: function(){
        // Formula E59
        return this.ceps.filter((obj) => {
          return obj.status=='trattativa';
        })
        .reduce((n, {calcd_csp_var_prod_est_piva_tot_eur}) => n+parseFloat(calcd_csp_var_prod_est_piva_tot_eur), 0);
      },

      calcd_det_trat_oth_prod_ext_eur: function(){
        // Formula E60
        return this.ceps.filter((obj) => {
          return obj.status=='trattativa';
        })
        .reduce((n, {calcd_csp_var_prod_other_est_eur}) => n+parseFloat(calcd_csp_var_prod_other_est_eur), 0);
      },

      calcd_det_trat_t_tot_perc: function(){
        // Formula F54 -> =(E54+E55)/E22
        return this.calcd_trat_impon_eur!=0 ? (this.calcd_det_trat_prod_int_eur+this.calcd_det_trat_mol_eur)/this.calcd_trat_impon_eur*100 : 0;
      },

      calcd_det_trat_b_tot_perc: function(){
        // Formula F56 -> =(E56+E57+E58+E59+E60)/E22
        return this.calcd_trat_impon_eur!=0 ? (this.calcd_det_trat_segn_eur+this.calcd_det_trat_ea_eur+this.calcd_det_trat_prod_int_piva_eur+this.calcd_det_trat_prod_ext_piva_eur+this.calcd_det_trat_oth_prod_ext_eur)/this.calcd_trat_impon_eur*100 : 0;
      },

      calcd_det_trat_comm_cost_eur: function(){
        // Formula E62
        return this.ceps.filter((obj) => {
          return obj.status=='trattativa';
        })
        .reduce((n, {calcd_01_new_sell_ext_eur, calcd_01_new_sell_oth_eur, calcd_01_new_sell_int_eur, calcd_01_upsell_int_eur, calcd_02_extra_margine_tot_eur, calcd_03_extra_margine_bonus_tot_eur}) => n+calcd_01_new_sell_ext_eur+calcd_01_new_sell_oth_eur+calcd_01_new_sell_int_eur+calcd_01_upsell_int_eur+calcd_02_extra_margine_tot_eur+calcd_03_extra_margine_bonus_tot_eur, 0);
      },

      calcd_det_trat_comm_cost_perc: function(){
        // Formula F62 -> =E62/E22
        return this.calcd_trat_impon_eur!=0 ? (this.calcd_det_trat_comm_cost_eur)/this.calcd_trat_impon_eur*100 : 0;
      },

      calcd_det_trat_dir_cost_eur: function(){
        // Formula E63
        return this.ceps.filter((obj) => {
          return obj.status=='trattativa';
        })
        .reduce((n, {calcd_cvb_dir_tot_eur}) => n+parseFloat(calcd_cvb_dir_tot_eur), 0);
      },

      calcd_det_trat_dir_cost_perc: function(){
        // Formula F63 -> =E63/E22
        return this.calcd_trat_impon_eur!=0 ? (this.calcd_det_trat_dir_cost_eur)/this.calcd_trat_impon_eur*100 : 0;
      },

      calcd_det_trat_prod_cost_eur: function(){
        // Formula E64
        return this.ceps.filter((obj) => {
          return obj.status=='trattativa';
        })
        .reduce((n, {calcd_cvb_prod_tot_eur}) => n+parseFloat(calcd_cvb_prod_tot_eur), 0);
      },

      calcd_det_trat_prod_cost_perc: function(){
        // Formula F64 -> =E64/E22
        return this.calcd_trat_impon_eur!=0 ? (this.calcd_det_trat_prod_cost_eur)/this.calcd_trat_impon_eur*100 : 0;
      },

      calcd_det_trat_mol_cost_eur: function(){
        // Formula E65
        return this.ceps.filter((obj) => {
          return obj.status=='trattativa';
        })
        .reduce((n, {calcd_cvb_mol_tot_eur}) => n+parseFloat(calcd_cvb_mol_tot_eur), 0);
      },

      calcd_det_trat_mol_cost_perc: function(){
        // Formula F65 -> =E65/E22
        return this.calcd_trat_impon_eur!=0 ? (this.calcd_det_trat_mol_cost_eur)/this.calcd_trat_impon_eur*100 : 0;
      },

      eas: function(){
        return this.users.filter((item) => { return item.is_ea; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },

      pls: function(){
        return this.users.filter((item) => { return item.is_pl; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },
    },

    methods: {
      ...helperFunctions,

      toggleSelectedEA(id){
        var index = this.selected_eas.indexOf(id);
        if(index>=0){
          this.selected_eas.splice(index, 1);
        }
        else{
          this.selected_eas.push(id);
        }
      },

      toggleSelectedPL(id){
        var index = this.selected_pls.indexOf(id);
        if(index>=0){
          this.selected_pls.splice(index, 1);
        }
        else{
          this.selected_pls.push(id);
        }
      },

      selectView(view){
        this.selected_view = view;
      },
    }
  }
</script>