<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-8">
        <template v-if="user">
          <h4>{{getUser(user).label}}</h4>
        </template>
        <table>
          <thead>
            <tr>
              <th class="as">Tipologia</th>
              <th class="ae">Venduto</th>
              <th class="ae">CCF (€)</th>
              <th class="ae">CCF (%)</th>
              <th class="ae">Copertura team fisso (€)</th>
              <th class="ae">Copertura team p.iva (€)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="as">LP</td>
              <td class="ae">{{$niceMoney(to_getIncValueSoldEur('lp', user))}}</td>
              <td class="ae">{{$niceMoney(to_getIncValueCCFEur('lp', user))}}</td>
              <td class="ae">{{$nicePerc(to_getIncValueCCFPerc('lp', user))}}</td>
              <td class="ae">{{$niceMoney(to_getIncValueCTFEur('lp', user))}}</td>
              <td class="ae">{{$niceMoney(to_getIncValueCTPEur('lp', user))}}</td>
            </tr>
            <tr>
              <td class="as">G2M</td>
              <td class="ae">{{$niceMoney(to_getIncValueSoldEur('g2m', user))}}</td>
              <td class="ae">{{$niceMoney(to_getIncValueCCFEur('g2m', user))}}</td>
              <td class="ae">{{$nicePerc(to_getIncValueCCFPerc('g2m', user))}}</td>
              <td class="ae">{{$niceMoney(to_getIncValueCTFEur('g2m', user))}}</td>
              <td class="ae">{{$niceMoney(to_getIncValueCTPEur('g2m', user))}}</td>
            </tr>
            <tr>
              <td class="as">CX</td>
              <td class="ae">{{$niceMoney(to_getIncValueSoldEur('cx', user))}}</td>
              <td class="ae">{{$niceMoney(to_getIncValueCCFEur('cx', user))}}</td>
              <td class="ae">{{$nicePerc(to_getIncValueCCFPerc('cx', user))}}</td>
              <td class="ae">{{$niceMoney(to_getIncValueCTFEur('cx', user))}}</td>
              <td class="ae">{{$niceMoney(to_getIncValueCTPEur('cx', user))}}</td>
            </tr>
            <tr>
              <td class="as">EDU</td>
              <td class="ae">{{$niceMoney(to_getIncValueSoldEur('edu', user))}}</td>
              <td class="ae">{{$niceMoney(to_getIncValueCCFEur('edu', user))}}</td>
              <td class="ae">{{$nicePerc(to_getIncValueCCFPerc('edu', user))}}</td>
              <td class="ae">{{$niceMoney(to_getIncValueCTFEur('edu', user))}}</td>
              <td class="ae">{{$niceMoney(to_getIncValueCTPEur('edu', user))}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12 col-md-4">
        <div class="co-inc-c-wrapper" :class="user ? 'wu' : ''">
          <p>Aggregato copertura costi fissi</p>
          <div class="i-co-inc-c-wrapper" ref="inc_to_chart"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as helperFunctions from "../../helpers/helpers"

  import * as am5 from '@amcharts/amcharts5'
  import * as am5percent from '@amcharts/amcharts5/percent'
  import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

  export default {
    props: {
      cea: Object,
      ceps: Array,
      user: Number,
      eas: Array,
      pls: Array,
    },

    data(){
      return {
        inc_to_chart: null,
      }
    },

    mounted(){
      this.$nextTick(() => {
        this.renderChart();
      });
    },

    methods: {
      ...helperFunctions,

      getUser(user_id){
        var obj = {id: null, label: '-'};
        var ea_results = this.eas.filter((item) => { return item.value==user_id; });
        if(ea_results && ea_results.length>0){
          obj = ea_results[0];
        }
        var pl_results = this.pls.filter((item) => { return item.value==user_id; });
        if(pl_results && pl_results.length>0){
          obj = pl_results[0];
        }
        return obj;
      },

      filterCeps(obj=null, user=null, main_kind=null){
        var flag = true;
        if(obj.status!='consolidato'){
          flag = false;
        }
        if(user){
          if(user!=obj.ea_id && user!=obj.pl_id){
            flag = false;
          }
        }
        if(main_kind && obj.main_kind!=main_kind){
          flag = false;
        }
        return flag;
      },

      to_getIncValueSoldEur(value, user){
        var eur = 0;
        switch(value){
          case 'lp':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'lp');
            })
            .reduce((n, {calcd_prezzo_vendita_tot_eur}) => n+calcd_prezzo_vendita_tot_eur, 0);
            break;
          case 'g2m':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'g2m');
            })
            .reduce((n, {calcd_prezzo_vendita_tot_eur}) => n+calcd_prezzo_vendita_tot_eur, 0);
            break;
          case 'cx':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'cx');
            })
            .reduce((n, {calcd_prezzo_vendita_tot_eur}) => n+calcd_prezzo_vendita_tot_eur, 0);
            break;
          case 'edu':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'edu');
            })
            .reduce((n, {calcd_prezzo_vendita_tot_eur}) => n+calcd_prezzo_vendita_tot_eur, 0);
            break;
          default:break;
        }
        return eur;
      },

      to_getIncValueCCFEur(value, user){
        var eur = 0;
        switch(value){
          case 'lp':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'lp');
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur, calcd_csp_fissi_mol_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur+calcd_csp_fissi_mol_tot_eur, 0);
            break;
          case 'g2m':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'g2m');
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur, calcd_csp_fissi_mol_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur+calcd_csp_fissi_mol_tot_eur, 0);
            break;
          case 'cx':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'cx');
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur, calcd_csp_fissi_mol_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur+calcd_csp_fissi_mol_tot_eur, 0);
            break;
          case 'edu':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'edu');
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur, calcd_csp_fissi_mol_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur+calcd_csp_fissi_mol_tot_eur, 0);
            break;
          default:break;
        }
        return eur;
      },

      to_getIncValueCCFPerc(value, user){
        var perc = this.to_getIncValueCCFEur(value, user)!=0 ? (this.to_getIncValueCCFEur(value, user)/this.to_getIncValueSoldEur(value, user)*100) : 0;
        return perc;
      },

      to_getIncValueCTFEur(value, user){
        var eur = 0;
        switch(value){
          case 'lp':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'lp');
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur, 0);
            break;
          case 'g2m':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'g2m');
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur, 0);
            break;
          case 'cx':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'cx');
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur, 0);
            break;
          case 'edu':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'edu');
            })
            .reduce((n, {calcd_csp_fissi_prod_int_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur, 0);
            break;
          default:break;
        }
        return eur;
      },

      to_getIncValueCTPEur(value, user){
        var eur = 0;
        switch(value){
          case 'lp':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'lp');
            })
            .reduce((n, {calcd_csp_var_prod_int_piva_tot_eur}) => n+calcd_csp_var_prod_int_piva_tot_eur, 0);
            break;
          case 'g2m':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'g2m');
            })
            .reduce((n, {calcd_csp_var_prod_int_piva_tot_eur}) => n+calcd_csp_var_prod_int_piva_tot_eur, 0);
            break;
          case 'cx':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'cx');
            })
            .reduce((n, {calcd_csp_var_prod_int_piva_tot_eur}) => n+calcd_csp_var_prod_int_piva_tot_eur, 0);
            break;
          case 'edu':
            eur = this.ceps.filter((obj) => {
              return this.filterCeps(obj, user, 'edu');
            })
            .reduce((n, {calcd_csp_var_prod_int_piva_tot_eur}) => n+calcd_csp_var_prod_int_piva_tot_eur, 0);
            break;
          default:break;
        }
        return eur;
      },

      renderChart(){
        if(this.inc_to_chart){
          this.inc_to_chart.dispose();
        }
        var inc_to_chart = am5.Root.new(this.$refs.inc_to_chart);
        inc_to_chart.setThemes([am5themes_Animated.new(inc_to_chart)]);

        var chart = inc_to_chart.container.children.push(
          am5percent.PieChart.new(inc_to_chart, {
            radius: am5.percent(50),
            innerRadius: am5.percent(60)
          })
        );

        // Define data
        var data = [
          {
            category: 'LP',
            value: this.to_getIncValueCCFEur('lp', this.user),
          },
          {
            category: 'G2M',
            value: this.to_getIncValueCCFEur('g2m', this.user),
          },
          {
            category: 'CX',
            value: this.to_getIncValueCCFEur('cx', this.user),
          },
          {
            category: 'EDU',
            value: this.to_getIncValueCCFEur('edu', this.user),
          },
        ];

        var series = chart.series.push(
          am5percent.PieSeries.new(inc_to_chart, {
            name: 'Series',
            categoryField: 'category',
            valueField: 'value',
          })
        );

        series.get('colors').set('colors', [
          am5.color(0x981869),
          am5.color(0xFF9300),
          am5.color(0x8BD0EF),
          am5.color(0x9B49ED)
        ]);

        series.data.setAll(data);

        series.labels.template.setAll({
          maxWidth: 170,
          textAlign: 'left',
          oversizedBehavior: 'wrap',
        });

        this.inc_to_chart = inc_to_chart;
      },
    }
  }
</script>