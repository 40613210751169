<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-8">
        <template v-if="user">
          <h4>{{getUser(user).label}}</h4>
        </template>
        <table>
          <thead>
            <tr>
              <th class="as">Segnalatore</th>
              <th class="ae">Venduto</th>
              <th class="ae">CCF (€)</th>
              <th class="ae">CCF (%)</th>
              <th class="ae">Copertura team fisso (€)</th>
              <th class="ae">Copertura team p.iva (€)</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="signalman in getSignalmen(user)">
              <tr>
                <td class="as">{{signalman.name}}</td>
                <td class="ae">{{$niceMoney(ol_getIncValueSoldEur(signalman.id, user))}}</td>
                <td class="ae">{{$niceMoney(ol_getIncValueCCFEur(signalman.id, user))}}</td>
                <td class="ae">{{$nicePerc(ol_getIncValueCCFPerc(signalman.id, user))}}</td>
                <td class="ae">{{$niceMoney(ol_getIncValueCTFEur(signalman.id, user))}}</td>
                <td class="ae">{{$niceMoney(ol_getIncValueCTPEur(signalman.id, user))}}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="col-12 col-md-4">
        <div class="co-inc-c-wrapper" :class="user ? 'wu' : ''">
          <p>Aggregato copertura costi fissi</p>
          <div class="i-co-inc-c-wrapper" ref="inc_ol_chart"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as helperFunctions from "../../helpers/helpers"

  import * as am5 from '@amcharts/amcharts5'
  import * as am5percent from '@amcharts/amcharts5/percent'
  import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

  export default {
    props: {
      cea: Object,
      ceps: Array,
      user: Number,
      eas: Array,
      pls: Array,
    },

    data(){
      return {
        inc_ol_chart: null,
      }
    },

    mounted(){
      this.$nextTick(() => {
        this.renderChart();
      });
    },

    methods: {
      ...helperFunctions,

      getUser(user_id){
        var obj = {id: null, label: '-'};
        var ea_results = this.eas.filter((item) => { return item.value==user_id; });
        if(ea_results && ea_results.length>0){
          obj = ea_results[0];
        }
        var pl_results = this.pls.filter((item) => { return item.value==user_id; });
        if(pl_results && pl_results.length>0){
          obj = pl_results[0];
        }
        return obj;
      },

      getSignalmen(user=null){
        var arr = this.ceps.filter((item) => { var flag = true; if(user && user!=item.ea_id && user!=item.pl_id){ flag = false; } return flag; }).map((item) => { return {id: item.signalman_id ? item.signalman_id : -1, name: item.signalman_name}; });
        return [...new Set(arr.map(i=>JSON.stringify(Object.fromEntries(
          Object.entries(i).sort(([a],[b])=>a.localeCompare(b))))
        ))].map(JSON.parse);
      },

      filterCeps(obj=null, user=null, signalman_id=null){
        var flag = true;
        if(obj.status!='consolidato'){
          flag = false;
        }
        if(user){
          if(user!=obj.ea_id && user!=obj.pl_id){
            flag = false;
          }
        }
        if(signalman_id){
          if(signalman_id==-1){
            if(obj.signalman_id!=null && obj.signalman_id!=0){
              flag = false;
            }
          }
          else{
            if(obj.signalman_id!=signalman_id){
              flag = false;
            }
          }
        }
        return flag;
      },

      ol_getIncValueSoldEur(value, user){
        var eur = this.ceps.filter((obj) => {
          return this.filterCeps(obj, user, value);
        })
        .reduce((n, {calcd_prezzo_vendita_tot_eur}) => n+calcd_prezzo_vendita_tot_eur, 0);
        return eur;
      },

      ol_getIncValueCCFEur(value, user){
        var eur = this.ceps.filter((obj) => {
          return this.filterCeps(obj, user, value);
        })
        .reduce((n, {calcd_csp_fissi_prod_int_tot_eur, calcd_csp_fissi_mol_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur+calcd_csp_fissi_mol_tot_eur, 0);
        return eur;
      },

      ol_getIncValueCCFPerc(value, user){
        var perc = this.ol_getIncValueCCFEur(value, user)!=0 ? (this.ol_getIncValueCCFEur(value, user)/this.ol_getIncValueSoldEur(value, user)*100) : 0;
        return perc;
      },

      ol_getIncValueCTFEur(value, user){
        var eur = this.ceps.filter((obj) => {
          return this.filterCeps(obj, user, value);
        })
        .reduce((n, {calcd_csp_fissi_prod_int_tot_eur}) => n+calcd_csp_fissi_prod_int_tot_eur, 0);
        return eur;
      },

      ol_getIncValueCTPEur(value, user){
        var eur = this.ceps.filter((obj) => {
          return this.filterCeps(obj, user, value);
        })
        .reduce((n, {calcd_csp_var_prod_int_piva_tot_eur}) => n+calcd_csp_var_prod_int_piva_tot_eur, 0);
        return eur;
      },

      renderChart(){
        if(this.inc_ol_chart){
          this.inc_ol_chart.dispose();
        }
        var inc_ol_chart = am5.Root.new(this.$refs.inc_ol_chart);
        inc_ol_chart.setThemes([am5themes_Animated.new(inc_ol_chart)]);

        var chart = inc_ol_chart.container.children.push(
          am5percent.PieChart.new(inc_ol_chart, {
            radius: am5.percent(50),
            innerRadius: am5.percent(60)
          })
        );

        // Define data
        var data = [];
        var clients = this.getSignalmen(this.user);
        for(var i=0;i<clients.length;i++){
          var client = clients[i];
          var obj = {
            category: client.name,
            value: this.ol_getIncValueCCFEur(client.id, this.user),
          };
          data.push(obj);
        }

        var series = chart.series.push(
          am5percent.PieSeries.new(inc_ol_chart, {
            name: 'Series',
            categoryField: 'category',
            valueField: 'value',
          })
        );

        series.data.setAll(data);

        series.labels.template.setAll({
          maxWidth: 170,
          textAlign: 'left',
          oversizedBehavior: 'wrap',
        });

        this.inc_ol_chart = inc_ol_chart;
      },
    }
  }
</script>